<script>
	import playerInfoComponent from './playerInfoComponent.vue'

	export default {
		props: ['playerStats', 'limit', 'team'],
		components: {
			playerInfoComponent
		},
		data() {
			return {
				sortColumn: 'goals',
				sortOrder: 'desc',
				playerInfoPop: false,
				selectedPlayer: 0
			};
		},
		computed: {
			sortedPlayerStats() {
				const statsCopy = [...this.playerStats];
				if (this.sortColumn) {
					statsCopy.sort((a, b) => {
					const valueA = a[this.sortColumn];
					const valueB = b[this.sortColumn];
					if (this.sortOrder === 'asc') {
						return valueA - valueB;
					} else {
						return valueB - valueA;
					}
					});
				}
				return statsCopy;
			},
		},
		methods: {
			sortPlayers(column) {
			if (this.sortColumn === column) {
				this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortColumn = column;
				this.sortOrder = 'desc';
			}
			},
			toggle (id) {
				this.selectedPlayer = id;
				this.playerInfoPop = true;
			},
			closePlayerInfo() {
				this.playerInfoPop = false;
			}
		}
	};
</script>

<template>
	<div v-if="!playerStats.length" class="players-not-available">
		<div class="greyheader greyheader-flex">
			<div class="greyheader-player">
				<p>{{team}}</p>
			</div>
			<div class="greyheader-value" id="grey-shots">
				<p>Shots</p>
			</div>
			<div class="greyheader-value" id="grey-target">
				<p>On target</p>
			</div>
			<div class="greyheader-value" id="grey-goals">
				<p>Goals</p>
			</div>
		</div>
		<div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>No player data available</h3>
		</div>
	</div>
	<div v-if="playerStats.length" class="greyheader greyheader-flex">
		<div class="greyheader-player">
			<p>{{team}}</p>
		</div>
		<div class="greyheader-value" id="grey-minutes" v-bind:class="sortColumn" @click="sortPlayers('minutes')">
			<p>Avg. min.</p>
			<img v-if="sortColumn === 'minutes'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
		<div class="greyheader-value" id="grey-assists" v-bind:class="sortColumn" @click="sortPlayers('assists')">
			<p>Assists</p>
			<img v-if="sortColumn === 'assists'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
		<div class="greyheader-value" id="grey-goals" v-bind:class="sortColumn" @click="sortPlayers('goals')">
			<p>Goals</p>
			<img v-if="sortColumn === 'goals'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
	</div>
	<div class="list">
		<div class="player-row" v-for="playerStat in sortedPlayerStats" :key="playerStat.player.id" v-on:click="toggle(playerStat.player.id)">
			<div class="listrow-player">
				<div class="player">
					<div class="position-circle">
						<p>{{ playerStat.player.shortPosition }}</p>
					</div>
					<p>{{ playerStat.player.name }}</p>
				</div>
				<img src="../assets/img/graph.png" />
			</div>
			<div class="listrow-value player-starts-value" v-bind:class="`player-starts-value`">
				<p>{{ playerStat.minutes }}</p>
			</div>
			<div class="listrow-value player-assists-value" v-bind:class="`player-assists-value-${playerStat.assists * 10}`">
				<p>{{ playerStat.assists }}</p>
			</div>
			<div class="listrow-value player-goals-value" v-bind:class="`player-goals-value-${playerStat.goals * 10}`">
				<p>{{ playerStat.goals }}</p>
			</div>
		</div>
	</div>
	<playerInfoComponent v-if="playerInfoPop" :playerId="this.selectedPlayer" :limit="this.limit" :team="this.team" @close-player="closePlayerInfo"></playerInfoComponent>
</template>

<style scoped src="../assets/css/style.css"></style>
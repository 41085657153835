<script>
	import { httpGet } from '../assets/js/utils.js'

	export default {
		props: ['fixture', 'selected_tab', 'limit'],
		components: {
		},
		watch: {
			limit: function() {
				this.getData()
			}
		},
		data() {
			return {
				oddsData: [],
				isLoading: false,
				hasOdds: true,
			};
		},
		methods: {
			async getData() {
				try {
					this.isLoading = true;
					this.bookmakerId = 6;

					await this.$nextTick();
					let url = `${process.env.VUE_APP_BACKEND_URL}/api/bestbets/odds/${this.fixture}`;
					if (this.bookmakerId) {
						url += `?bookmakerId=${this.bookmakerId}`;
					}

					this.oddsData = await httpGet(url);
					this.hasOdds = this.oddsData.length > 0;
					this.$emit('updateOddsVisibility', this.hasOdds);
				} catch (error) {
					// Remove the odds component if there is an error
					console.error(error);
					this.hasOdds = false;
					this.$emit('updateOddsVisibility', this.hasOdds);
				} finally {
					this.isLoading = false;
				}
			}
		},
		created() {
			this.getData()
		}
	};
</script>

<template>
	<div class="odd-card" v-if="selected_tab === 'last5'">
		<div class="odds-container">
			<div class="odds-logo">
				<img src="../assets/img/statty-logo-small.png">
			</div>
			<div class="odds-group-container">
				<div class="odd-group home">
					<p>Home</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.home }}</p></div>
				</div>
				<div class="odd-group draw">
					<div class="odd"><p>{{ oddsData[0]?.matchWinner?.draw }}</p></div>
				</div>
				<div class="odd-group away">
					<div class="odd label-after"><p>{{ oddsData[0]?.matchWinner?.away }}</p></div>
					<p>Away</p>
				</div>
			</div>
		</div>
	</div>
	<div class="odd-card" v-if="selected_tab === 'goals'">
		<div class="odds-container">
			<div class="odds-group-container">
				<div class="odd-group divider">
					<p>Over 2.5</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_two_five }}</p></div>
				</div>
				<!-- <div class="odd-group btts">
					<p>BTTS</p>
				</div> -->
				<div class="odd-group btts">
					<p>Yes</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.bothTeamsToScore?.yes }}</p></div>
					<p class="btts-label">BTTS</p>
					<div class="odd label-after"><p>{{ oddsData[0]?.bothTeamsToScore?.no }}</p></div>
					<p>No</p>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="odd-card" v-if="selected_tab === 'shots'">
		<div class="odds-container">
			<div class="odd-group divider">
				<div class="odd label-after"><p>1.79</p></div>
				<p>Over 25.5 shots</p>
			</div>
			<div class="odd-group">
				<div class="odd label-after"><p>1.79</p></div>
				<p>Over 8.5 OT</p>
			</div>
		</div>
	</div> -->
</template>

<style scoped src="../assets/css/style.css">
</style>
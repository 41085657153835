<script>
import leagueComponent from './components/leagueComponent.vue'
import { httpGet } from './assets/js/utils.js'

export default {
	name: 'App',
	components: {
		leagueComponent
	},
	data() {
		return {
			// TODO: THESE IDs CHANGE!!!!
			leagues: [],
			loadFirst: {
				today: 0,
				tomorrow: 0,
				all: 0
			},
			showError: false,
			isNavBarHidden: false,
			lastScrollPos: 0,
			leaguesWithData: [],
			filterBy: "today",
			view: {
				topOfPage: true,
				topOfPageAction: false
			},
			showSearch: false,
			showSearchAction: false,
			actionPicker: false,
			limit: 10,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
			// showFeedback: false
		}
	},
	beforeMount() {
		// TODO: wat doet dit precies? dit zorgt voor heel veel vue-re-computes (bij elke scroll)
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('scroll', this.handleScrollUp);
		window.addEventListener('scroll', this.scrollActionBar);
	},
	// mounted() {
    // 	window.addEventListener('scroll', this.handleScroll);
	// },
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScrollUp);
	},
	methods: {
		showLeague(fixtureCount) {
			if (this.filterBy == 'today') {
				return fixtureCount.today !== null && fixtureCount.today > 0;
			} else if (this.filterBy == 'tomorrow') {
				return fixtureCount.tomorrow !== null && fixtureCount.tomorrow > 0;
			} else if (this.filterBy == 'all') {
				return fixtureCount.all !== null && fixtureCount.all > 0;
			}
			return false
		},
		getFixtureCount(fixtureDates) {
			// date in browser timeZone
			var now = Date.now()
			now = new Date(now)
			var today = new Date(now)
			today.setHours(23,59,59)
			var tomorrow = new Date(today)
			tomorrow.setDate(tomorrow.getDate() + 1)
			tomorrow.setHours(23,59,59)
			var week = new Date(today)
			week.setDate(tomorrow.getDate() + 7)
			tomorrow.setHours(23,59,59)
			// JS Date automatically converts dates to browser timezone
			var fixtureDatesTimezone = fixtureDates.map(function(fixtureDate) {
				fixtureDate = new Date(fixtureDate.date);
				return fixtureDate;
			});
			let fixtureCount = { "today": 0, "tomorrow": 0, "all": 0 };

			fixtureDatesTimezone.map(function(date) {
				if (date < today) {
					fixtureCount['today']++
				} else if (date < tomorrow) {
					fixtureCount['tomorrow']++
				} else {
					fixtureCount['all']++
				}
			});

			return fixtureCount
		},
		async getActiveLeagues() {
			try {
				// Store fetch result in leagues
				let leagues = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/leagues?timeZone=${this.timeZone}`);
				// Check if leagues is an array and not empty --> define oprder based on local & set prioLeague limit (3)
				if (Array.isArray(leagues) && leagues.length > 0) {
					this.leagues = await this.orderLeaguesByTimeZone(leagues);
					this.loadFirst.today = leagues.find(league => league.fixtureCount.today > 0) ? leagues.find(league => league.fixtureCount.today > 0).id : leagues[0];
					this.loadFirst.tomorrow = leagues.find(league => league.fixtureCount.tomorrow > 0) ? leagues.find(league => league.fixtureCount.tomorrow > 0).id : leagues[0];
					this.loadFirst.all = leagues.find(league => league.fixtureCount.all > 0) ? leagues.find(league => league.fixtureCount.all > 0).id : leagues[0];
				}
				document.getElementById("loadingOverlay").style.display = "none";
				document.getElementById("bodyOverflow").style.overflow = "auto";
			} catch (error) {
				// ADD: error message on product to show to consumer?
				console.error('Error fetching or processing leagues:', error);
				document.getElementById("loadingOverlay").style.display = "none";
				document.getElementById("bodyOverflow").style.overflow = "auto";
				this.showError = true;
			}
		},
		// Define order based on locale & JSON files, run from getActiveLeagues
		async orderLeaguesByTimeZone(leagues) {
			var countriesPerTimezone = require('./countriesPerTimezone.json')
			var localizations = require('./localizations.json')

			// Check timeZone & define country, has default
			const country = countriesPerTimezone[this.timeZone] ? countriesPerTimezone[this.timeZone] : "Britain (UK)"
			var index = leagues.findIndex(x => x.id === localizations[country]);
			if (index > -1 ) {
				leagues[index]["priority"] = 9
			}

			// Sort the updated leagues on priority
			leagues.sort((a,b) => a.priority - b.priority);

			leagues.map(function(league) {
				league['fixtureCount'] = this.getFixtureCount(league.fixtureDates)
			}, this);

			return leagues
		},
		// Split leagues in high- & lowPriority
		leagueDataFiltered(id) {
			if(this.leaguesWithData.indexOf(id) === -1) {
				this.leaguesWithData.push(id)
			}
		},
		// Run by navigation
		setFilter(filterBy) {
			this.filterBy = filterBy
			// this.getActiveLeagues();
		},
		// Limit on matches
		setLimit(limit) {
			this.limit = limit
		},

		// SCROLL FUNCTIONS
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
		handleScroll() {
			if(window.pageYOffset>60){
				if(this.view.topOfPage) this.view.topOfPage = false
			} else {
				if(!this.view.topOfPage) this.view.topOfPage = true
			}
		},
		handleScrollUp() {
			if(window.pageYOffset>400){
				const currentScrollPos = window.scrollY;

				if (currentScrollPos > this.lastScrollPos) {
					this.isNavBarHidden = true;
				} else {
					this.isNavBarHidden = false;
				}
				this.lastScrollPos = currentScrollPos;
			}
		},
		scrollActionBar() {
			if(window.pageYOffset>160){
				if(this.view.topOfPageAction) this.view.topOfPageAction = false
			} else {
				if(!this.view.topOfPageAction) this.view.topOfPageAction = true
			}
		},

		// SEARCH FUNCTIONS
		showSearchList() {
			this.showSearch = !this.showSearch;
		},
		showSearchListAction() {
			this.showSearchAction = !this.showSearchAction;

			if (this.showSearchAction) {
				document.getElementById("bodyOverflow").style.overflow = "hidden";
			} else {
				document.getElementById("bodyOverflow").style.overflow = "auto";
			}

		},
		showActionPicker() {
			this.actionPicker = !this.actionPicker;
		}
	},
	created() {
		this.getActiveLeagues()
		// setTimeout(() => this.showFeedback = true, 10 * 1000)
	}
}
</script>

<template>
	<head>
		<title>Statty - The spot for football statistics</title>
		<link rel="preconnect" href="https://fonts.googleapis.com">
		<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
		<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet">
		<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap" rel="stylesheet">
		<link href="https://fonts.googleapis.com/css2?family=Sen:wght@400;500;600;700;800&display=swap" rel="stylesheet">
		<meta charset="utf-8" />
		<meta http-equiv="X-UA-Compatible" content="IE=edge"/>
		<meta name="HandheldFriendly" content="True"/>
		<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
		<link rel="apple-touch-icon" sizes="180x180" href="{{ url_for('static', filename='img/favicon/apple-touch-icon.png' ) }}">
		<link rel="icon" type="image/png" sizes="32x32" href="{{ url_for('static', filename='img/favicon/favicon-32x32.png' ) }}">
		<link rel="icon" type="image/png" sizes="16x16" href="{{ url_for('static', filename='img/favicon/favicon-16x16.png' ) }}">
		<!-- <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js" type="text/javascript"></script> -->
		<!-- <script src="https://code.jquery.com/jquery-3.1.1.min.js"></script> -->
		<!-- <script src="{{ url_for('static', filename='js/utils.js') }}"></script> -->
	</head>

	<div id="loadingOverlay">
		<img src="./assets/img/spinner.gif" class="spinner">
		<h1>Just a moment... training the server to do bicycle kicks!</h1>
	</div>

	<!-- <div class="action-bar" :class="{'onScrollActionBar': !view.topOfPageAction, 'actionBarSearch': showSearchAction }"> -->
	<div class="action-bar" :class="{ hidden: showSearchAction }">
		<div class="action-bar-normal">
			<div class="action-logo">
				<img class="logo" src="img/statty-logo-blue.png">
			</div>
			<div class="action-item" @click="showActionPicker" :class="{ hidden: actionPicker }">
				<h3>{{ limit }} matches</h3>
			</div>
			<div class="action-item clicked-action" @click="showActionPicker" :class="{ hidden: !actionPicker }">
				<h3>Select period</h3>
			</div>
			<div class="action-item action-search" @click="showSearchListAction" >
				<img src="./assets/img/search.png" />
				<h3>{{ filterBy }}</h3>
			</div>
		</div>
	</div>

	<div class="action-picker" :class="{ hidden: !actionPicker }">
		<div class="picker-choice" @click="showActionPicker" v-on:click="setLimit(3)">
			<h3>3 matches</h3>
		</div>
		<div class="picker-choice" @click="showActionPicker" v-on:click="setLimit(5)">
			<h3>5 matches</h3>
		</div>
		<div class="picker-choice" @click="showActionPicker" v-on:click="setLimit(10)">
			<h3>10 matches</h3>
		</div>
	</div>

	<div class="search-list-action" :class="{ searchVisibleAction: showSearchAction }">
		<div class="league-overview">
			<div class="search-league-highlighted" v-if="leaguesWithData.length > 0">
				<div class="highlighted-leagues">
					<div class="searchwrap" @click="showSearchListAction" v-for="league in leagues" :key="league.id" :id="league.id">
						<h2 v-show="showLeague(league.fixtureCount) && league.priority < 20 && league.priority == this.leagues[0].priority">Highlighted leagues</h2>
						<h2 v-show="leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)) && league.id == leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)).id">All leagues</h2>
						<a :href="'#' + league.name + league.country" v-show="showLeague(league.fixtureCount)">
							<div class="search-item">
								<img v-bind:src="league.flag" /><h3>{{ league.name }}</h3>
							</div>
						</a>
					</div>
				</div>
			</div>

			<div class="no-leagues" v-if="leaguesWithData.length == 0">
				<div v-if="filterBy=='today'">
					<h2>No supported leagues with matches today</h2>
					<a v-on:click="setFilter('tomorrow')"><h3>Discover tomorrow's matches</h3></a>
				</div>
				<div v-if="filterBy=='tomorrow'">
					<h2>No supported leagues with matches tomorrow</h2>
					<a v-on:click="setFilter('all')"><h3>Discover the coming 7 days</h3></a>
				</div>
			</div>
		</div>
		<div class="close-bar">
			<div class="close-btn" @click="showSearchListAction">
				<h3>Close leagues</h3>
				<img src="./assets/img/close-white.png" />
			</div>
		</div>
	</div>

	<div class="header header-mobile">
		<div class="header-wrapper">
			<a href="https://statty.app"><img src="img/statty-logo.png"></a>
		</div>
	</div>
	<div class="main-app">
		<!-- <div class="filter-wrapper" :class="{'onScroll': !view.topOfPage}"> -->
		<div class="filter-wrapper">

			<div class="header header-desktop">
				<div class="header-wrapper">
					<a href="https://statty.app"><img src="img/statty-logo.png"></a>
				</div>
			</div>
			<nav>
				<div class="nav-wrapper">
					<h2 id="tab-today" v-on:click="setFilter('today')" v-bind:class="filterBy" @click="scrollToTop">Today</h2>
					<h2 id="tab-tomorrow" v-on:click="setFilter('tomorrow')" v-bind:class="filterBy" @click="scrollToTop">Tomorrow</h2>
					<h2 id="tab-all" v-on:click="setFilter('all')" v-bind:class="filterBy" @click="scrollToTop">7 days</h2>
				</div>
			</nav>
			<div class="filter">

				<!-- <div class="live-btn">
					<img src="./assets/img/view.png"><p>live matches</p>
				</div> -->

				<div class="league-navigation-desktop" :class="{ 'onScrollLeagues': !view.topOfPage}">
					<div class="search-list">
						<div class="all-leagues" v-if="leaguesWithData.length > 0">
							<div class="searchwrap" v-for="league in leagues" :key="league.id" :id="league.id">
								<h2 v-show="this.showLeague(league.fixtureCount) && league.priority < 20 && league.priority == this.leagues[0].priority">Highlighted leagues</h2>
								<h2 v-show="leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)) && league.id == leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)).id">All leagues</h2>
								<a :href="'#' + league.name + league.country" v-show="showLeague(league.fixtureCount)">
									<div class="search-item">
										<img v-bind:src="league.flag" /><h3>{{ league.name }}</h3>
									</div>
								</a>
							</div>
						</div>

						<div class="no-leagues" v-if="leaguesWithData.length == 0">
							<div v-if="filterBy=='today'">
								<h2>No supported leagues with matches today</h2>
								<a v-on:click="setFilter('tomorrow')"><h3>Discover tomorrow's matches</h3></a>
							</div>
							<div v-if="filterBy=='tomorrow'">
								<h2>No supported leagues with matches tomorrow</h2>
								<a v-on:click="setFilter('all')"><h3>Discover the coming 7 days</h3></a>
							</div>
						</div>
					</div>
					<div class="data-picker-desktop" :class="{ 'onScrollPicker': !view.topOfPage}">
						<h3>Select matches</h3>
						<div class="picker-choice-desktop" v-on:click="setLimit(3)" :class="{ selected: limit === 3 }">
							<h3>3</h3>
						</div>
						<div class="picker-choice-desktop" v-on:click="setLimit(5)" :class="{ selected: limit === 5 }">
							<h3>5</h3>
						</div>
						<div class="picker-choice-desktop" v-on:click="setLimit(10)" :class="{ selected: limit === 10 }">
							<h3>10</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="leagues" :class="{'onScrollMargin': !view.topOfPage}"> -->
		<div class="leagues onScrollMargin">
			<!-- <div class="low-prio-title" v-show="leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)) && league.id == leagues.find(league => league.priority > 20 && this.showLeague(league.fixtureCount)).id">
				<h1>All leagues</h1>
			</div> -->
			<leagueComponent
				class="league"
				ref="leagueComponent"
				v-for="(league, index) in leagues"
				v-show="showLeague(league.fixtureCount)"
				:key="league.id"
				:index="index"
				:leagueName="league.name"
				:leaguePrio="league.priority"
				:countryFlag="league.flag"
				:countryName="league.country"
				:id="league.id"
				:fixtureCount="league.fixtureCount"
				:filterBy="filterBy"
				@league-data-filtered="leagueDataFiltered(league.id)"
			></leagueComponent>

			<!-- <div v-show="showFeedback" class="no-more-message">
				<div class="img-circle-container">
					<img src="./assets/img/referee-whistle.png">
				</div>
				<h1>We'd love to hear from you</h1>
				<h1>We want to make a better Statty</h1>
				<h1>Do you have 1 minute to give us feedback?</h1>
				<a><button class="button-cta">Let's go</button></a>
			</div> -->

			<div class="no-more-message" v-if="filterBy=='today' && !showError || filterBy=='tomorrow' && !showError">
				<div class="img-circle-container">
					<img src="./assets/img/referee-whistle.png">
				</div>
				<h1 v-if="filterBy=='today'">No more matches today</h1>
				<h1 v-if="filterBy=='tomorrow'">No more matches tomorrow</h1>
				<h1>Have a look at</h1>
				<a v-on:click="setFilter('today')" v-if="filterBy=='tomorrow'"><button class="button-cta" @click="scrollToTop">Today</button></a>
				<a v-on:click="setFilter('tomorrow')" v-if="filterBy=='today'"><button class="button-cta" @click="scrollToTop">Tomorrow</button></a>
				<a v-on:click="setFilter('all')" v-if="filterBy=='today' || filterBy=='tomorrow'"><button class="button-cta" @click="scrollToTop">Next 7 days</button></a>
			</div>
			<div class="no-more-message error-message" v-if="showError">
				<div class="img-circle-container">
					<img src="./assets/img/red-card.png">
				</div>
				<h1>That's a foul!</h1>
				<h2>Statty is temporarily sidelined.</h2>
				<h2>We'll be back on the field shortly.</h2>
			</div>
		</div>
	</div>
</template>

<style scoped src="./assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>

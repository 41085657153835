<script>
	import playerShotsComponent from './playerShotsComponent.vue'
	import playerGoalsComponent from './playerGoalsComponent.vue'
	import { httpGet } from '../assets/js/utils.js'
	export default {
		props: ['fixture', 'selected_tab', 'limit', 'teamsPlaying'],
		components: {
			playerShotsComponent,
			playerGoalsComponent
		},
		watch: {
			limit: function() {
				this.fetchPlayerShotsData()
			}
		},
		data() {
			return {
				playerShotsData: null,
				playerGoalsData: null,
				isLoadingPlayerShots: false,
				isLoadingPlayerGoals: false,
				selectedPlayersTab: 'shots',
			};
		},
		methods: {
			async fetchPlayerShotsData() {
				this.selectedPlayersTab = 'shots'
				if(this.playerShotsData === null) {
					try {
						this.isLoadingPlayerShots = true;
						this.playerShotsData = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/shots/player/${this.fixture}?limit=${this.limit}`);
					} catch (error) {
						console.error('Error fetching player shots data:', error);
					} finally {
						this.isLoadingPlayerShots = false;
					}
				} else {
					// console.log("data already fetched")
				}

				// return httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/shots/player/${this.fixture}?limit=${this.limit}`);
			},
			async fetchPlayerGoalsData() {
				this.selectedPlayersTab = 'goals'
				if(this.playerGoalsData === null) {
					try {
						this.isLoadingPlayerGoals = true;
						this.playerGoalsData = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/goals/player/${this.fixture}?limit=${this.limit}`);
					} catch (error) {
						console.error('Error fetching player shots data:', error);
					} finally {
						this.isLoadingPlayerGoals = false;
					}
				}
			},
		},
		// TODO: make URL dynamic LIVE vs TEST; move this to method? see leagueComponent.vue
		created() {
			this.fetchPlayerShotsData()
		}
	};
</script>

<template>
	<div class="crd-tab" id="crd-tab-players" v-if="selected_tab == 'players'">
		<!-- <div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>Shots data coming soon</h3>
		</div> -->
		<!-- <div class="loading-card" v-if="isLoading">
			<img src="../assets/img/spinner.gif" class="spinner">
			<h2>Calculating shots...</h2>
		</div> -->
		<div class="crd-nav">
			<h3 id="players-tab-shots" v-on:click="fetchPlayerShotsData" v-bind:class="selectedPlayersTab">Shots</h3>
			<h3 id="players-tab-goals" v-on:click="fetchPlayerGoalsData" v-bind:class="selectedPlayersTab">Goals</h3>
		</div>

		<div class="player-goals" v-if="selectedPlayersTab == 'goals'">
			<div class="player-list" v-if="isLoadingPlayerGoals">
				<div class="list-home">
					<div class="greyheader greyheader-flex">
						<div class="greyheader-player">
							<p>{{this.teamsPlaying[0]}}</p>
						</div>
						<div class="greyheader-value">
							<p>Avg. min</p>
						</div>
						<div class="greyheader-value">
							<p>Assists</p>
						</div>
						<div class="greyheader-value">
							<p>Goals</p>
						</div>
					</div>
					<div class="list skeleton-list">
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
					</div>
				</div>
				<div class="list-away">
					<div class="greyheader greyheader-flex">
						<div class="greyheader-player">
							<p>{{this.teamsPlaying[1]}}</p>
						</div>
						<div class="greyheader-value">
							<p>Shots</p>
						</div>
						<div class="greyheader-value">
							<p>On target</p>
						</div>
						<div class="greyheader-value">
							<p>Goals</p>
						</div>
					</div>
					<div class="list skeleton-list">
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>

					</div>
				</div>
			</div>
			<div class="player-list" v-if="!isLoadingPlayerGoals">
				<div class="list-home">
					<KeepAlive>
						<playerGoalsComponent :playerStats="playerGoalsData.players.home" :limit="this.limit" :team="this.teamsPlaying[0]"></playerGoalsComponent>
					</KeepAlive>
				</div>
				<div class="list-away">
					<KeepAlive>
						<playerGoalsComponent :playerStats="playerGoalsData.players.away" :limit="this.limit" :team="this.teamsPlaying[1]"></playerGoalsComponent>
					</KeepAlive>
				</div>
			</div>
		</div>

		<div class="player-shots" v-if="selectedPlayersTab == 'shots'">
			<div class="player-list" v-if="isLoadingPlayerShots">
				<div class="list-home">
					<div class="greyheader greyheader-flex">
						<div class="greyheader-player">
							<p>{{this.teamsPlaying[0]}}</p>
						</div>
						<div class="greyheader-value">
							<p>Avg. min</p>
						</div>
						<div class="greyheader-value">
							<p>On target</p>
						</div>
						<div class="greyheader-value">
							<p>Goals</p>
						</div>
					</div>
					<div class="list skeleton-list">
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
					</div>
				</div>
				<div class="list-away">
					<div class="greyheader greyheader-flex">
						<div class="greyheader-player">
							<p>{{this.teamsPlaying[1]}}</p>
						</div>
						<div class="greyheader-value">
							<p>Shots</p>
						</div>
						<div class="greyheader-value">
							<p>On target</p>
						</div>
						<div class="greyheader-value">
							<p>Goals</p>
						</div>
					</div>
					<div class="list skeleton-list">
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
						<div class="player-row">
							<div class="listrow-player">
								<div class="skeleton skeleton-player"></div>
							</div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
							<div class="listrow-value player-shots-value skeleton"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="player-list" v-if="!isLoadingPlayerShots">
				<div class="list-home">
					<KeepAlive>
						<playerShotsComponent :playerStats="playerShotsData.players.home" :limit="this.limit" :team="this.teamsPlaying[0]"></playerShotsComponent>
					</KeepAlive>
				</div>
				<div class="list-away">
					<KeepAlive>
						<playerShotsComponent :playerStats="playerShotsData.players.away" :limit="this.limit" :team="this.teamsPlaying[1]"></playerShotsComponent>
					</KeepAlive>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
	.crd-tab > div:not(.spinner) {
		display: none;
	}
	/* Show the spinner when isLoading is true */
	.crd-tab .spinner {
		display: block;
	}
</style>

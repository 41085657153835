<script>
	import fixtureCard from './fixtureCard.vue'
	import loadingLeagueComponent from './loadingLeagueComponent.vue'
	import { httpGet } from '../assets/js/utils.js'

	export default {
		props: ['leagueName', 'countryFlag', 'countryName', 'id', 'filterBy', 'index', 'fixtureCount'],
		watch: {
			filterBy: function () {
				if (this.$parent.loadFirst[this.filterBy] == this.id) {
					this.getData()
					this.hideLeague = false
				} else {
					this.hideLeague = true
				}
				this.filterFixtures(this.filterBy)
			}
		},
		components: {
			fixtureCard,
			loadingLeagueComponent
		},
		data() {
				return {
						leagueData: [],
						leagueDataFiltered: [],
						show: false,
						hideLeague: true,
						loaded: false,
						scrollPosition: 0,
						scrollStep: 480,
						isRightButtonDisabled: false
				};
		},
		methods: {
				async getData() {
					if (!this.loaded) {
						this.leagueData = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/fixtures/${this.id}`)
						this.loaded = true
						this.filterFixtures(this.filterBy)
					}
				},

				// estimates fixture end to be max 2 hours after beginning; TODO; not accurate! Z for UTC
				// only filter fully loaded data! else it'll wait for the 'data-fetched' emit event
				filterFixtures(filterBy) {
					if (this.loaded && this.leagueData.fixtures.length > 0) {
						var now = new Date()
						var tomorrow = new Date()
						var dayAfter = new Date()
						tomorrow.setHours(23,59,59,0);
						dayAfter.setDate(tomorrow.getDate() + 1);
						dayAfter.setHours(23,59,59,0);

						var toFilter = this.leagueData.fixtures;
						this.leagueDataFiltered.fixtures = toFilter.filter(function (fixture) {
							var fixtureStart = new Date(fixture.matchTime + "Z")
							var fixtureEnd = new Date(fixture.matchTime + "Z")
							fixtureEnd.setHours(fixtureEnd.getHours() + 2);
							if(filterBy == 'today') {
								return fixtureEnd >= now && fixtureStart <= tomorrow;
							} else if (filterBy == 'tomorrow') {
								return fixtureStart >= tomorrow && fixtureStart <= dayAfter;
							} else {
								return true
							}
						});

						if (this.leagueDataFiltered.fixtures.length > 0) {
							this.$emit('league-data-filtered');
						}
					}
				},

				completeStandings(standings, teamsPerGroup, homeTeamId) {
					var group = Object.keys(teamsPerGroup).find(key => teamsPerGroup[key].includes(homeTeamId));
					var standingsPerGroup = standings.filter( standing => standing.group == group );
					return standingsPerGroup
				},

				filterStandings(standings, homeTeam, awayTeam) {
					var standingsFiltered = standings.filter(f => f.team == homeTeam || f.team == awayTeam);
					return standingsFiltered
				},
				scrollLeft() {
					this.scrollPosition -= this.scrollStep;
					this.updateScrollPosition();
				},
				scrollRight() {
					this.scrollPosition += this.scrollStep;
					this.updateScrollPosition();
				},
				updateScrollPosition() {
					const listContainer = this.$refs.listContainer;
					if (listContainer) {
						listContainer.scrollLeft = this.scrollPosition;
						this.isRightButtonDisabled = listContainer.scrollLeft + listContainer.clientWidth === listContainer.scrollWidth;
					}
				},
				showLeague() {
					this.hideLeague = !this.hideLeague;
					this.getData()
				}

		},
		computed: {
			totalFixtures() {
				return this.leagueDataFiltered.fixtures.length;
			}
		},
		// Only load first league + open the first league
		created() {
			if (this.$parent.loadFirst[this.filterBy] == this.id) {
				this.getData()
				this.hideLeague = false
			}
		}
	}
</script>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>

<template>
	<div class="lge" :class="{ foldedlge: hideLeague }" v-bind:id="leagueName+countryName">
		<div class="lge-back">
			<div class="lge-header lge-mobile" @click="showLeague">
				<!-- <div v-if="this.countryName === 'World'" class="lge-title">
					<img v-bind:src="countryFlag" /><h1>{{ leagueName }}</h1>
				</div> -->
				<div class="lge-title">
					<img v-bind:src="countryFlag" />
					<div class="title">
						<p>{{ countryName }}</p>
						<!-- <p v-if="countryName !== 'World'">{{ countryName }}</p> -->
						<!-- <h1 v-if="countryName !== 'World'"> - </h1> -->
						<h1>{{ leagueName }}</h1>
					</div>
				</div>
				<div class="lge-interaction">
					<div class="match-counter">
						<p v-if="filterBy == 'today' && fixtureCount.today == 1">{{ fixtureCount.today }} match</p>
						<p v-if="filterBy == 'today' && fixtureCount.today > 1">{{ fixtureCount.today }} matches</p>
						<p v-if="filterBy == 'tomorrow' && fixtureCount.tomorrow == 1">{{ fixtureCount.tomorrow }} match</p>
						<p v-if="filterBy == 'tomorrow' && fixtureCount.tomorrow > 1">{{ fixtureCount.tomorrow }} matches</p>
						<p v-if="filterBy == 'all' && fixtureCount.all == 1">{{ fixtureCount.all }} match</p>
						<p v-if="filterBy == 'all' && fixtureCount.all > 1">{{ fixtureCount.all }} matches</p>
					</div>
					<div class="hide-btn">
						<img src="../assets/img/arrow-down.png" :class="[{ arrowDown: hideLeague }, { arrowDownOpen: !hideLeague }]"/>
					</div>
				</div>
			</div>
			<div class="lge-header lge-big" @click="showLeague">
				<div class="lge-title" v-bind:id="leagueName">
					<img v-bind:src="countryFlag" />
					<div class="title">
						<p>{{ countryName }}</p>
						<!-- <p v-if="countryName !== 'World'">{{ countryName }}</p> -->
						<!-- <h1 v-if="countryName !== 'World'"> - </h1> -->
						<h1>{{ leagueName }}</h1>
					</div>
				</div>
				<div class="lge-interaction">
					<div class="match-counter">
						<p v-if="filterBy == 'today' && fixtureCount.today == 1">{{ fixtureCount.today }} match</p>
						<p v-if="filterBy == 'today' && fixtureCount.today > 1">{{ fixtureCount.today }} matches</p>
						<p v-if="filterBy == 'tomorrow' && fixtureCount.tomorrow == 1">{{ fixtureCount.tomorrow }} match</p>
						<p v-if="filterBy == 'tomorrow' && fixtureCount.tomorrow > 1">{{ fixtureCount.tomorrow }} matches</p>
						<p v-if="filterBy == 'all' && fixtureCount.all == 1">{{ fixtureCount.all }} match</p>
						<p v-if="filterBy == 'all' && fixtureCount.all > 1">{{ fixtureCount.all }} matches</p>
					</div>
					<div class="hide-btn">
						<img src="../assets/img/arrow-down.png" :class="[{ arrowDown: hideLeague }, { arrowDownOpen: !hideLeague }]"/>
					</div>
				</div>
			</div>
		</div>

		<loadingLeagueComponent v-show="!hideLeague && !loaded"></loadingLeagueComponent>

		<div class="matches" :class="{ hidden: hideLeague }">
			<div class="fixture-list" ref="listContainer">
				<fixtureCard
					v-for="fixture in leagueDataFiltered.fixtures"
					:key="fixture.id"
					:fixture="fixture"
					:leagueId="id"
					:standings="filterStandings(leagueData.standingsData.standings, fixture.homeTeam.id, fixture.awayTeam.id)"
					:completeStandings="completeStandings(leagueData.standingsData.standings, leagueData.standingsData.teamsPerGroup, fixture.homeTeam.id)"
				></fixtureCard>
			</div>
			<div class="scroll-btns" v-if="filterBy == 'today' && fixtureCount.today > 1 || filterBy == 'tomorrow' && fixtureCount.tomorrow > 1 || filterBy == 'all' && fixtureCount.all > 1">
				<button class="left" @click="scrollLeft" :disabled="scrollPosition === 0" >
					<img v-if="scrollPosition === 0" src="../assets/img/arrow-left-dsbld.png" />
					<img v-if="scrollPosition > 0" src="../assets/img/arrow-left.png" />
				</button>
				<button class="right" @click="scrollRight" :disabled="isRightButtonDisabled">
					<img v-if="isRightButtonDisabled" src="../assets/img/arrow-right-dsbld.png" />
					<img v-if="!isRightButtonDisabled" src="../assets/img/arrow-right.png" />
				</button>
			</div>
		</div>
	</div>
</template>

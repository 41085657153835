<script>
	import { httpGet } from '../assets/js/utils.js'

	export default {
		props: ['fixture', 'leagueId', 'round', 'selected_tab', 'limit'],
		components: {
		},
		watch: {
			limit: function() {
				this.getData()
			}
		},
		data() {
			return {
				isLoading: false,
				isSecondFixture: false,
				previousFixtureData: null,
				ballPossessionHome: null,
				ballPossessionAway: null,
			};
		},
		methods: {
			async getData() {
				try {
					this.isLoading = true;
					await this.$nextTick();
					let url = `${process.env.VUE_APP_BACKEND_URL}/api/bestbets/knockout/${this.fixture}/${this.leagueId}`;
					this.previousFixtureData = await httpGet(url);
					// Check op length nodig?
					// this.isSecondFixture = this.previousFixtureData && this.previousFixtureData.data && this.previousFixtureData.length > 0;
					this.isSecondFixture = typeof this.previousFixtureData.data !== 'undefined' && this.previousFixtureData.data.fixtureDetails.processState === 'completed';
					this.ballPossessionHome = Math.round(this.previousFixtureData.data.home.statistics.ballPossession)
					this.ballPossessionAway = Math.round(this.previousFixtureData.data.away.statistics.ballPossession)
					this.$emit('knockoutVisibility', this.isSecondFixture);
				} catch (error) {
					console.error(error);
					this.isSecondFixture = false;
					this.$emit('knockoutVisibility', this.isSecondFixture);
				} finally {
					this.isLoading = false;
				}
			}
		},
		computed: {
			isHomePossessionHigher() {
				if (!this.previousFixtureData || !this.previousFixtureData.data) {
					return false;
				}
				const homePossession = parseFloat(this.previousFixtureData.data.home.statistics.ballPossession);
				const awayPossession = parseFloat(this.previousFixtureData.data.away.statistics.ballPossession);
				return homePossession > awayPossession;
			}
		},
		created() {
			this.getData()
		}
	};
</script>

<template>
	<div class="knockoutFirstRound stnd-not-available" v-if="!this.isSecondFixture">
		<div class="greyheader">
			<div class="stnd-name">
				<p>{{ this.round }}</p>
			</div>
		</div>
		<div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>First knockout match</h3>
		</div>
	</div>
	<div class="knockoutResult" v-if="this.isSecondFixture">
		<div class="greyheader">
			<p>{{ this.round }} - First knockout result</p>
		</div>
		<div class="knockout-result">
			<div class="teams">
				<div class="teamname">
					<h3>{{ previousFixtureData?.data?.home?.team?.name }}</h3>
				</div>
				<div class="score1">
					<h2>{{ previousFixtureData?.data?.home?.goals }}</h2>
				</div>
				<h3>-</h3>
				<div class="score2">
					<h2>{{ previousFixtureData?.data?.away?.goals }}</h2>
				</div>
				<div class="teamname">
					<h3>{{ previousFixtureData?.data?.away?.team?.name }}</h3>
				</div>
			</div>
			<div class="score" v-if="previousFixtureData?.data?.away?.statistics && previousFixtureData?.data?.home?.statistics">
				<div class="cards-received">
					<div class="yellow-cards" :class="{ cardsApplicable: previousFixtureData?.data?.home?.statistics?.yellowCards > 0 }">
						<div class="card-label"><h3>{{ previousFixtureData?.data?.home?.statistics?.yellowCards }}</h3></div>
						<div class="card-yellow"></div>
					</div>
					<div class="red-cards" :class="{ cardsApplicable: previousFixtureData?.data?.home?.statistics?.redCards > 0}">
						<div class="card-label"><h3>{{ previousFixtureData?.data?.home?.statistics?.redCards }}</h3></div>
						<div class="card-red"></div>
					</div>
				</div>
				<div class="ball-poss">
					<h3 :class="{ 'poss-score': true, 'highest-poss': isHomePossessionHigher }">{{ ballPossessionHome }}</h3>
					<p class="ball-poss-label">ball poss.</p>
					<h3 :class="{ 'poss-score': true, 'highest-poss': !isHomePossessionHigher }">{{ ballPossessionAway }}</h3>
				</div>
				<div class="cards-received cards-received-away">
					<div class="yellow-cards" :class="{ cardsApplicable: previousFixtureData?.data?.away?.statistics?.yellowCards > 0 }">
						<div class="card-label"><h3>{{ previousFixtureData?.data?.away?.statistics?.yellowCards }}</h3></div>
						<div class="card-yellow"></div>
					</div>
					<div class="red-cards" :class="{ cardsApplicable: previousFixtureData?.data?.away?.statistics?.redCards > 0 }">
						<div class="card-label"><h3>{{ previousFixtureData?.data?.away?.statistics?.redCards }}</h3></div>
						<div class="card-red"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="knockout-datapoints" v-if="previousFixtureData?.data?.away?.statistics && previousFixtureData?.data?.home?.statistics">
			<div class="knockout-labels">
				<div>
					<p>Shots</p>
				</div>
				<div>
					<p>On Target</p>
				</div>
				<div>
					<p>Corners</p>
				</div>
				<div class="break"></div>
				<div>
					<p>Shots</p>
				</div>
				<div>
					<p>On Target</p>
				</div>
				<div>
					<p>Corners</p>
				</div>
			</div>
			<div class="knockout-data">
				<div class="avg shots-value" v-bind:class="shots-stats-color-15">
					<p>{{ previousFixtureData?.data?.home?.statistics?.totalShots }}</p>
				</div>
				<div class="avg ot-shots-value" v-bind:class="ot-shots-stats-color-5">
					<p>{{ previousFixtureData?.data?.home?.statistics?.shotsOnGoal }}</p>
				</div>
				<div class="avg corner-value">
					<p>{{ previousFixtureData?.data?.home?.statistics?.cornerKicks }}</p>
				</div>
				<div class="break"></div>
				<div class="avg shots-value" v-bind:class="shots-stats-color-12">
					<p>{{ previousFixtureData?.data?.away?.statistics?.totalShots }}</p>
				</div>
				<div class="avg ot-shots-value" v-bind:class="ot-shots-stats-color-2">
					<p>{{ previousFixtureData?.data?.away?.statistics?.shotsOnGoal }}</p>
				</div>
				<div class="avg corner-value">
					<p>{{ previousFixtureData?.data?.away?.statistics?.cornerKicks }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
</style>

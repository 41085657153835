<script>
	import statsShotsRowComponent from './statsShotsRowComponent.vue'
	import statsCornersRowComponent from './statsCornersRowComponent.vue'
	import statsCardsRowComponent from './statsCardsRowComponent.vue'
	import { httpGet } from '../assets/js/utils.js'

	export default {
		props: ['fixture', 'selected_tab', 'limit'],
		components: {
			statsShotsRowComponent,
			statsCornersRowComponent,
			statsCardsRowComponent,
		},
		watch: {
			limit: function() {
				this.getData()
			}
		},
		data() {
			return {
			teamShotsData: null,
			teamCornersData: null,
			teamCardsData: null,
			isLoadingTeamShots: true,
			isLoadingTeamCorners: true,
			isLoadingTeamCards: true,
		};
		},
		methods: {
			async fetchTeamShotsData() {
				this.isLoadingTeamShots = true;
				try {
					this.teamShotsData = await httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/shots/${this.fixture}?limit=${this.limit}`);
				} catch (error) {
					console.error('Error fetching shots data:', error);
				} finally {
					this.isLoadingTeamShots = false;
				}
			},
			async fetchCornersData() {
				this.isLoadingTeamCorners = true;
				const url = `${process.env.VUE_APP_BACKEND_URL}/api/bestbets/corners/${this.fixture}?limit=${this.limit}`;
				try {
					this.teamCornersData = await httpGet(url);
				} catch (error) {
					console.error('Error fetching corners data:', error);
					this.teamCornersData = { home: { total: {} }, away: { total: {} } }; // Fallback data structure
				} finally {
					this.isLoadingTeamCorners = false;
				}
			},
			async fetchCardsData() {
				this.isLoadingTeamCards = true;
				const url = `${process.env.VUE_APP_BACKEND_URL}/api/bestbets/cards/${this.fixture}?limit=${this.limit}`;
				try {
					this.teamCardsData = await httpGet(url);
				} catch (error) {
					console.error('Error fetching cards data:', error);
					this.teamCardsData = { home: { total: {} }, away: { total: {} } }; // Fallback data structure
				} finally {
					this.isLoadingTeamCards = false;
				}
			},
			async getData() {
				this.fetchTeamShotsData();
				this.fetchCornersData();
				this.fetchCardsData();
			}
		},
		// TODO: make URL dynamic LIVE vs TEST; move this to method? see leagueComponent.vue
		created() {
			this.getData()
		}
	};
</script>

<template>
	<div class="crd-tab" id="crd-tab-shots" v-if="selected_tab == 'shots'">
		<!-- <div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>Shots data coming soon</h3>
		</div> -->
		<!-- <div class="loading-card" v-if="isLoading">
			<img src="../assets/img/spinner.gif" class="spinner">
			<h2>Calculating shots...</h2>
		</div> -->
		<div class="shots-stats">
			<div class="greyheader greyheader-flex">
				<div class="greyheader-value">
					<p>Shots</p>
				</div>
				<div class="greyheader-value">
					<p>On Target</p>
				</div>
				<div class="greyheader-label">
					<p class="title">Avg. last {{this.limit}}</p>
				</div>
				<div class="greyheader-value">
					<p>Shots</p>
				</div>
				<div class="greyheader-value">
					<p>On target</p>
				</div>
			</div>
			<div v-if="isLoadingTeamShots">
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Total</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Home | Away</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
			</div>
			<div v-if="!isLoadingTeamShots">
				<statsShotsRowComponent 
					:totalShotsHome="teamShotsData.teams.home.shotsTotal.total" 
					:totalShotsAway="teamShotsData.teams.away.shotsTotal.total" 
					:onTargetShotsHome="teamShotsData.teams.home.onTarget.total" 
					:onTargetShotsAway="teamShotsData.teams.away.onTarget.total" 
					:label="'Total'">
				</statsShotsRowComponent>
				<statsShotsRowComponent 
					:totalShotsHome="teamShotsData.teams.home.shotsTotal.home" 
					:totalShotsAway="teamShotsData.teams.away.shotsTotal.away" 
					:onTargetShotsHome="teamShotsData.teams.home.onTarget.home" 
					:onTargetShotsAway="teamShotsData.teams.away.onTarget.away" 
					:label="'Home | Away'">
				</statsShotsRowComponent>
			</div>
		</div>

		<!-- Implement corners -->
		<div class="corner-stats">
			<div class="greyheader greyheader-flex">
				<div class="greyheader-value">
					<p>For</p>
				</div>

				<div class="greyheader-value">
					<p>Against</p>
				</div>

				<div class="greyheader-label">
					<p class="title">Corners</p>
				</div>
				<div class="greyheader-value">
					<p>For</p>
				</div>

				<div class="greyheader-value">
					<p>Against</p>
				</div>

			</div>
			<div v-if="isLoadingTeamCorners">
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Total</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Home | Away</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
			</div>
			<div v-if="!isLoadingTeamCorners">
				<statsCornersRowComponent 
					:totalCornersForHome="teamCornersData.home.total.for" 
					:totalCornersAgainstHome="teamCornersData.home.total.against" 
					:totalCornersForAway="teamCornersData.away.total.for" 
					:totalCornersAgainstAway="teamCornersData.away.total.against" 
					:label="'Total'">
				</statsCornersRowComponent>
				<statsCornersRowComponent 
					:totalCornersForHome="teamCornersData.home.single.for" 
					:totalCornersAgainstHome="teamCornersData.home.single.against" 
					:totalCornersForAway="teamCornersData.away.single.for" 
					:totalCornersAgainstAway="teamCornersData.away.single.against" 
					:label="'Home | Away'">
				</statsCornersRowComponent>
			</div>
		</div>

		<!-- Implement Cards -->
		<div class="card-stats">
			<div class="greyheader greyheader-flex">
				<div class="greyheader-value">
					<p>Received</p>
				</div>

				<div class="greyheader-value">
					<p>Opponent</p>
				</div>

				<div class="greyheader-label">
					<p class="title">Cards</p>
				</div>
				<div class="greyheader-value">
					<p>Received</p>
				</div>

				<div class="greyheader-value">
					<p>Opponent</p>
				</div>

			</div>
			<div v-if="isLoadingTeamCards ">
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Total</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
				<div class="stats-row">
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
					<div class="stats-label">
						<p>Home | Away</p>
					</div>
					<div class="avg-box skeleton"></div>
					<div class="avg-box skeleton"></div>
				</div>
			</div>
			<div v-if="!isLoadingTeamCards && teamCardsData">
				<statsCardsRowComponent 
					:cardsTotalYellowHomeReceived="teamCardsData.home.total.received.yellow" 
					:cardsTotalYellowHomeOpponent="teamCardsData.home.total.opponent.yellow" 
					:cardsTotalRedHomeReceived="teamCardsData.home.total.received.red" 
					:cardsTotalRedHomeOpponent="teamCardsData.home.total.opponent.red"
					:cardsTotalYellowAwayReceived="teamCardsData.away.total.received.yellow" 
					:cardsTotalYellowAwayOpponent="teamCardsData.away.total.opponent.yellow" 
					:cardsTotalRedAwayReceived="teamCardsData.away.total.received.red" 
					:cardsTotalRedAwayOpponent="teamCardsData.away.total.opponent.red" 
					:label="'Total'">
				</statsCardsRowComponent>
				<statsCardsRowComponent 
					:cardsTotalYellowHomeReceived="teamCardsData.home.single.received.yellow" 
					:cardsTotalYellowHomeOpponent="teamCardsData.home.single.opponent.yellow" 
					:cardsTotalRedHomeReceived="teamCardsData.home.single.received.red" 
					:cardsTotalRedHomeOpponent="teamCardsData.home.single.opponent.red"
					:cardsTotalYellowAwayReceived="teamCardsData.away.single.received.yellow" 
					:cardsTotalYellowAwayOpponent="teamCardsData.away.single.opponent.yellow" 
					:cardsTotalRedAwayReceived="teamCardsData.away.single.received.red" 
					:cardsTotalRedAwayOpponent="teamCardsData.away.single.opponent.red" 
					:label="'Home | Away'">
				</statsCardsRowComponent>
			</div>
			<div v-if="!isLoadingTeamCards && !teamCardsData" class="stnd-not-available">
				<div class="message">
					<img src="../assets/img/stnd-icon.png">
					<h3>No cards data available</h3>
				</div>
			</div>
			<div class="greyspace-bg-shots">
			</div>
		</div> 
	</div>
</template>

<style scoped src="../assets/css/style.css">
	.crd-tab > div:not(.spinner) {
		display: none;
	}

	/* Show the spinner when isLoading is true */
	.crd-tab .spinner {
		display: block;
	}
</style>
